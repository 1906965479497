


































































































































































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import ChatTopCommandHandlerModuleBuilder from '@/includes/logic/Modules/models/modules/ChatTopCommandHandlerModule/ChatTopCommandHandlerModuleBuilder'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    NotAvailableOptionsOverlay,
    MultiMessageEditorWithMediaInput,
    SwitchInput,
    ExtendedHelpMessage,
    CenteredColumnLayout,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ChatTopCommandHandlerModuleView extends Mixins(ModuleBindings, TariffsTagsHelper, InputSetups) {
  @VModel() module!: ChatTopCommandHandlerModuleBuilder

  mounted() {
    if (this.getTagsByFieldKey('custom_tops')) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show(this.saveModule)
    }
  }
}
